
import { CHAIN_IDS } from 'config/web3/chains';

const W_ETH_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.ROPSTEN]: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  [CHAIN_IDS.KOVAN]: '0x0000000000000000000000000000000000000000',
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [CHAIN_IDS.ARBITRUM]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
};

export {
  W_ETH_ADDRESSES
};
